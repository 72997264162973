<div class="section">
    <div class="container">
        <div class="row">
            <!-- Blog Posts -->
            <div class="col-12 col-lg-12">
                <h4>Privacy Policy</h4>
                <p> We respect and are committed to protecting your privacy. Publishing, selling, or renting any
                    personal data or information to any third party is against our ethics.</p>
                <p> For more clarity on how we use and protect your personal information, please go through our privacy
                    policy thoroughly.</p>

                <p>The privacy practices of this statement apply exclusively to our services available under the domain
                    of https://studiokanchana.com. By visiting this website you agree to be bound by the terms and
                    conditions of this Privacy Policy. If you do not agree please do not use or access our website. By
                    accepting the Privacy Policy and the Terms & Conditions Agreement while registering, you expressly
                    consent to our use and disclosure of your personal information in accordance with this Privacy
                    Policy. This Privacy Policy is effective upon acceptance during registration and is otherwise
                    effective on carrying out any transaction with us for all users. In the course of registering for
                    and availing of various services, we provide from time to time through our website (Website,
                    telephone search, SMS, and WAP) or any other Media medium in which studiokanchana Sarees may provide
                    services you may be required to disclose your name, residence address, email address, telephone
                    number, and similar Personal Information.</p>

                <p>Personal Information is used for two general purposes: to process your order and to provide you with
                    the best possible services. Unless otherwise stated explicitly, this Policy applies to Personal
                    Information as disclosed on any of the Media.</p>

                <p>If you send us personal correspondence, such as emails or letters, or if other users or third parties
                    send us correspondence about your activities or postings on the Website, we may collect such
                    information into a file specific to you.</p>
                <p>
                    We use third-party service providers to process purchases you make and to monitor our users’
                    interests. In addition, the Website may occasionally contain links to Third-Party Sites. If you
                    click on the links to Third-Party Websites, you leave the Website. We are not responsible for the
                    content of these Third-Party Websites or for the security of your personal information when you use
                    the Third Party Websites. These third-party service providers and Third-Party Sites may have their
                    own privacy policies governing the storage and retention of the personal information that you may be
                    subject to. They may collect information such as your IP address, browser specification, or
                    operating system. This Privacy Policy does not govern personal information provided to, stored on,
                    or used by these third-party providers and Third-Party Sites. We recommend that when you enter a
                    Third-Party Site, you review the Third Party Site’s privacy policy as it relates to safeguarding
                    your personal information.</p>

                <p>You acknowledge that you are disclosing Personal Information voluntarily. Prior to the completion of
                    any registration process on our website or prior to availing of any services offered on our website
                    if you wish not to disclose any Personal Information you may refrain from doing so; however, if you
                    don’t provide information that is requested it is possible that the registration process would be
                    incomplete and/or you would not be able to avail of our services.</p>


                <h5>PRIVACY GUARANTEE</h5>
                <p>We promise that we will not sell or rent your personal information to third parties for their
                    marketing purposes. Personal Information may be shared only under one or more of the following
                    circumstances:</p>
                <ul>
                    <li>(a) If we have your consent or deemed consent to do so;</li>
                    <li>(b) If we are compelled by law (including court orders) to do so.</li>
                </ul>

                <p>
                    We give you the ability to edit your account information and preferences at any time. To protect
                    your privacy and security, we will also take reasonable steps to verify your identity before
                    granting access or making corrections. While we strive to use a commercially acceptable means to
                    protect the personal information you provide, we cannot guarantee its security. Therefore, you
                    acknowledge and agree that we assume no liability regarding the theft, loss, alteration or misuse of
                    personal or other information or Content, including, without limitation, such information that has
                    been provided to third parties or other users, or with regards to the failure of a third party to
                    abide by the agreement between us and such third party.</p>

                <p>Further, you have the right to refuse any marketing email from us in the future.</p>

                <p>For further details/queries regarding the privacy policy, you may contact us at
                    {{'kanchie56@gmail.com'}}</p>
            </div>
        </div>
    </div>
</div>