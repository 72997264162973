<div class="breadcrumbs">
    <div class="breadcrumbsection">
        <ul class="breadcrumb">
            <li><a class="m-link">Home</a></li>
            <li><a class="m-link category" routerLink="/category-product/{{ category._id }}">{{ category.name }}</a></li>
            <li><a class="m-link subcategory">{{ subcategory.name }}</a></li>
            <li><a class="m-link product">{{ product.name }}</a></li>
          </ul>
    </div>
</div>
<div class="section pt-7 pb-5">
    <div class="container">
        <div class="row col-spacing-50">
            <div class="col-12 col-lg-6 text-center">
                <img src="{{ bigimage }}" alt=""  style="height:450px;">
                <ul class="product-carousel-thumbnails" *ngIf="product.photos.length > 0">
                    <li *ngFor="let image of product.photos">
                        <a href="javascript::void(0)" style="border: 1px solid #ddd;">
                            <img src="{{ image.secure_url }}" height="100" alt="" (mouseenter)="changeImage(image.secure_url)">
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-lg-6">
                <h3 class="font-weight-normal margin-0">{{ product.name }}</h3>
                <!-- <div class="product-price">
                    <h5 class="font-weight-normal">Rs. <ins>{{ product.splprice }}.00 </ins></h5>
                </div> -->
                <p>{{ product.short_description }}</p>
                <div class = "product-detail">
                    <ul style="padding:0px;" *ngIf="updatedAttributes.length > 0">
                        <li *ngFor="let attr of updatedAttributes; let i=index;">
                           <div class="attributes">
                            <span style="margin-right: 10px;">
                                {{ attr.attribute_name }}: 
                               </span> 
                               <span>
                                    <mat-chip-list aria-label="Fish selection">
                                        <mat-chip color="accent" [selected]="selected_attributes.attribute_value == attrval" style="cursor:pointer;" 
                                        (click)="changeAttribute(attr.attribute_name, attrval)" 
                                        *ngFor="let attrval of attr.attribute_values">
                                            {{ attrval | lowercase }}
                                        </mat-chip>
                                    </mat-chip-list>
                               </span>
                           </div>
                        </li>
                      </ul>
                </div>
                <form class="product-quantity margin-top-30">
                    <div class="qnt">
                        <div class="wrap">
                            <div class="counter">
                              <input class="counter__input" type="text" value="{{ cartQuantity }}" name="counter" size="5" readonly="readonly"/>
                              <a class="counter__increment" (click)="increaseQuantity()">+</a>
                              <a class="counter__decrement" (click)="decreaseQuantity()">&ndash;</a>
                            </div>
                        </div>
                    </div>
                    <button class="button button-md button-dark" type="button" (click)="addToCart()">Add to Cart</button>
                </form>
                <div class="margin-top-30">
                    <p>Category: {{ product.category.name ? product.category.name : '' }}</p>
                    <!-- <a class="button-text-1 font-small uppercase margin-top-30" href="#">Add to Wishlist</a> -->
                </div>
                <div class="margin-top-30">
                     <img src="assets/images/payment_card.jpg" alt="payment card">
                </div>
            </div>
        </div><!-- end row -->
    </div><!-- end container -->
    <div class="container mt-5">
        <div class="product-info-box">
            <ul class="nav margin-bottom-20">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#description">Description</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#additional-info">Additional Info</a>
                </li> -->
            </ul>
            <div class="tab-content">
                <div class="tab-pane fade show active" id="description">
                    {{ product.long_description }}
                </div>
                <div class="tab-pane fade" id="additional-info">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th scope="row">Weight</th>
                                <td>54 g</td>
                            </tr>
                            <tr>
                                <th scope="row">Dimension</th>
                                <td>12 x 18 x 7 x 57 cm</td>
                            </tr>
                            <tr>
                                <th scope="row">Color</th>
                                <td>Black</td>
                            </tr>
                            <tr>
                                <th scope="row">Care</th>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div><!-- end container -->
</div>
<!-- New Arrivals -->
<div class="section-lg">
    <div class="container">
        <div class="text-center margin-bottom-70">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <h2 class="font-weight-normal">New Arrivals</h2>
                    <p>Discover elegance in our latest saree collection! Embrace timeless beauty with vibrant colors and exquisite designs, perfect for every occasion. Crafted with premium fabrics and intricate details, each saree radiates grace and sophistication. Redefine your wardrobe with these stunning new arrivals, made to elevate your style!</p>
                </div>
            </div>
        </div>
        <div class="row col-spacing-40">
            <div class="col-12 col-md-4" *ngFor="let product of newArrivals">
                <div class="product-box">
                    <div class="product-img">
                        <a routerLink="/single-product/{{ product._id }}">
                            <img src="{{ product.photo.secure_url }}" alt="" class="product-box-img">
                        </a>
                        <div class="product-badge-left">
                            <span class="font-small uppercase font-family-secondary font-weight-medium">New</span>
                        </div>
                    </div>
                    <div class="product-title">
                        <h6 class="font-weight-medium"><a routerLink="/single-product/{{ product._id }}">{{ product.name }}</a></h6>
                        <!-- <div class="price-and-cart">
                            <div class="price">
                                <span>₹ {{ product.splprice }}</span>
                            </div>
                            <div class="add-to-cart">
                                <a class="button-text-1" routerLink="/single-product/{{ product._id }}">View Product</a>
                            </div>
                        </div> -->
                        <div class="add-to-wishlist">
                            <a href="#"><i class="far fa-heart"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- end row -->
        <div class="text-center margin-top-70">
            <a class="button button-lg button-dark button-rounded" routerLink="/category-product/635bd3c6d0d666f43b30e046" href="javascript::void(0)">View More</a>
        </div>
    </div>
</div>
<!-- end New Arrivals -->

