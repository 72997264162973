import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cartItemList : any = localStorage.getItem('cart_service') ? JSON.parse(localStorage.getItem('cart_service')) : [];
  public productList = new BehaviorSubject<any>(this.cartItemList);

  constructor() { }

  getProduct = () =>{
    return this.productList.asObservable();
  }

  setProduct = (product:any) =>{
    this.cartItemList.push(...product);
    this.productList.next(product)
  }

  addToCart = (product:any, quantity?) =>{
    // Checking if the product is already in cart
    let alreadyInCart = false;
    if(this.cartItemList.length > 0){
      this.cartItemList.forEach((element, index) => {
        if(element._id === product._id && !product.attributes){
          if(quantity){
            this.cartItemList[index].quantity+= parseInt(quantity);
          }else{
            this.cartItemList[index].quantity++;
          }
          alreadyInCart = true;
        }
      });
    }
    // If New product receives
    if(!alreadyInCart){
      if(quantity) product.quantity = parseInt(quantity);
      this.cartItemList.push(product);
    }
    localStorage.setItem('cart_service', JSON.stringify(this.cartItemList));
    this.productList.next(this.cartItemList);
  }

  removeCartItem = (product:any) =>{
    this.cartItemList.map((element, index)=>{
      if(element._id === product._id){
        this.cartItemList.splice(index, 1);
      }
    });
    localStorage.setItem('cart_service', JSON.stringify(this.cartItemList));
    this.productList.next(this.cartItemList);
  }

  removeAllCart = () => {
    this.cartItemList = [];
    localStorage.setItem('cart_service', JSON.stringify(this.cartItemList));
    this.productList.next(this.cartItemList);
  }
}
