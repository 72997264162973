<section class="faq-section">
    <div class="container">
      <div class="row">
                        <!-- ***** FAQ Start ***** -->
                        <div class="col-md-6 offset-md-3">
    
                            <div class="faq-title text-center pb-3">
                                <h2>FAQ</h2>
                            </div>
                        </div>
                        <div class="col-md-6 offset-md-3">
                            <div class="faq" id="accordion">
                                <div class="card">
                                    <div class="card-header" id="faqHeading-1">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-1" data-aria-expanded="true" data-aria-controls="faqCollapse-1">
                                                <span class="badge">1</span>What is Studio Kanchana, and what do you offer?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-1" class="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
                                        <div class="card-body">
                                            <p> Studio Kanchana is a saree designing business that specializes in creating exquisite and personalized sarees for our customers. We offer a wide range of saree designs, including traditional, contemporary, and fusion styles, tailored to meet individual preferences.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-2">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-2" data-aria-expanded="false" data-aria-controls="faqCollapse-2">
                                                <span class="badge">2</span> How can I place an order for a saree?

                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-2" class="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
                                        <div class="card-body">
                                            <p> Placing an order with Studio Kanchana is easy. You can visit our website and explore our saree collections. Once you find a design you love, you can add it to your cart and proceed to checkout. Alternatively, you can contact our customer support team via phone or email, and they will guide you through the ordering process.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-3">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-3" data-aria-expanded="false" data-aria-controls="faqCollapse-3">
                                                <span class="badge">3</span>Can I request a custom-designed saree?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-3" class="collapse" aria-labelledby="faqHeading-3" data-parent="#accordion">
                                        <div class="card-body">
                                            <p>Absolutely! We take pride in offering personalized saree designs. If you have a specific idea or requirement in mind, you can share it with us, and our skilled designers will create a unique saree tailored to your preferences.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-4">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-4" data-aria-expanded="false" data-aria-controls="faqCollapse-4">
                                                <span class="badge">4</span> How long does it take to receive my custom saree?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-4" class="collapse" aria-labelledby="faqHeading-4" data-parent="#accordion">
                                        <div class="card-body">
                                            <p>The time required for a custom-designed saree can vary depending on the complexity of the design and our current workload. Our customer support team will provide you with an estimated delivery time when you place your order. Rest assured, we strive to deliver your saree within the shortest possible time without compromising on quality.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-5">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-5" data-aria-expanded="false" data-aria-controls="faqCollapse-5">
                                                <span class="badge">5</span> What are the payment options available?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-5" class="collapse" aria-labelledby="faqHeading-5" data-parent="#accordion">
                                        <div class="card-body">
                                            <p> We accept various payment methods for your convenience, including credit/debit cards, bank transfers, and online payment platforms. All transactions are processed securely to ensure your financial data is protected.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-6">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-6" data-aria-expanded="false" data-aria-controls="faqCollapse-6">
                                                <span class="badge">6</span>  Do you offer international shipping?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-6" class="collapse" aria-labelledby="faqHeading-6" data-parent="#accordion">
                                        <div class="card-body">
                                            <p>Yes, Studio Kanchana offers international shipping. We deliver our exquisite sarees to customers worldwide. Please note that international shipping may take longer due to customs and local delivery procedures.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-7">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">
                                                <span class="badge">7</span> Can I track my order?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                                        <div class="card-body">
                                            <p> Absolutely! Once your saree is shipped, we will provide you with a tracking number. You can use this number to track your package and stay updated on its delivery status.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-8">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-8" data-aria-expanded="false" data-aria-controls="faqCollapse-8">
                                                <span class="badge">8</span>What if I need alterations to my saree?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-8" class="collapse" aria-labelledby="faqHeading-8" data-parent="#accordion">
                                        <div class="card-body">
                                            <p>  If you need any alterations to your saree, please get in touch with our customer support team immediately. We will do our best to accommodate your request, subject to the specific design and material constraints.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-9">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-9" data-aria-expanded="false" data-aria-controls="faqCollapse-9">
                                                <span class="badge">9</span> What is your return and exchange policy?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-9" class="collapse" aria-labelledby="faqHeading-9" data-parent="#accordion">
                                        <div class="card-body">
                                            <p>  We want you to be completely satisfied with your purchase. If, for any reason, you are not happy with your saree, please contact us within [X] days of receiving it. We will guide you through the return or exchange process. Please note that custom-designed sarees may have different return/exchange policies.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-10">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-10" data-aria-expanded="false" data-aria-controls="faqCollapse-10">
                                                <span class="badge">10</span>How do I care for my saree?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-10" class="collapse" aria-labelledby="faqHeading-10" data-parent="#accordion">
                                        <div class="card-body">
                                            <p>To ensure your saree remains in pristine condition, we recommend dry cleaning or gentle hand washing with mild detergent. Avoid harsh chemicals and direct sunlight while storing your saree.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqHeading-11">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-11" data-aria-expanded="false" data-aria-controls="faqCollapse-11">
                                                <span class="badge">11</span> I have more questions. How can I contact you?
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-11" class="collapse" aria-labelledby="faqHeading-11" data-parent="#accordion">
                                        <div class="card-body">
                                            <p>We are here to help! You can reach out to our customer support team via phone, email, or through our website's contact form. We will be happy to assist you with any inquiries or concerns you may have.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    </section>