import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ourstory',
  templateUrl: './ourstory.component.html',
  styleUrls: ['./ourstory.component.css']
})
export class OurstoryComponent implements OnInit {
  isOpen = false;
  constructor() { }

  ngOnInit(): void {
  }
  readMore(){
     this.isOpen = !this.isOpen;
  }
}
