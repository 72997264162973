<section style="background-color: #eee;">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-4">
          <div class="card mb-4">
            <div class="card-body text-center">
              <img src="https://www.freeiconspng.com/thumbs/profile-icon-png/am-a-19-year-old-multimedia-artist-student-from-manila--21.png" alt="avatar"
                class="rounded-circle img-fluid" style="width: 150px;">
              <h5 class="my-3">{{ userForm.name }}</h5>
              <p class="text-muted mb-1">{{ userForm.email }}</p>
              <p class="text-muted mb-4">
                {{ userForm.address == undefined ? 'Not Updated' : userForm.address }}
              </p>
              <div class="d-flex justify-content-center mb-2">
                <a (click)="logout()">
                    <button type="button" class="btn btn-primary">Logout</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="card mb-4">
            <div class="card-body">
              <form name="form" #f="ngForm" (ngSubmit)="onSubmit()">
                <div class="spinner">
                    <mat-spinner *ngIf="spinner" class="spinner"></mat-spinner>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="mb-0">Basic Details</h5>
                  </div>
                  <div class="col-md-6 text-right">
                    <a class="btn btn-secondary" (click)="changeEditStatus()">Edit</a>
                  </div>
                  <div class="col-sm-3">
                    <p class="mb-0">Full Name</p>
                  </div>
                  <div class="col-sm-9">
                    <p class="text-muted mb-0" *ngIf="!edit">{{ userForm.name }}</p>
                    <input type="text" name="name" *ngIf="edit" [(ngModel)]="userForm.name" #name="ngModel" class="form-control">
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0">Email</p>
                  </div>
                  <div class="col-sm-9">
                    <p class="text-muted mailto:mb-0" *ngIf="!edit">{{ userForm.email }}</p>
                    <input type="email" name="email" [(ngModel)]="userForm.email" #email="ngModel" *ngIf="edit" class="form-control">
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0">Mobile</p>
                  </div>
                  <div class="col-sm-9">
                    <p class="text-muted mb-0" *ngIf="!edit">
                      {{ userForm.number == undefined ? '00000000' : userForm.number }}
                    </p>
                    <input type="number" name="number" [(ngModel)]="userForm.number" #number="ngModel" *ngIf="edit" class="form-control">
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0">Address</p>
                  </div>
                  <div class="col-sm-9">
                    <p class="text-muted mb-0" *ngIf="!edit">
                      {{ userForm.address == undefined ? 'Not Updated' : userForm.address }}
                    </p>
                    <input type="text" name="address" [(ngModel)]="userForm.address" #address="ngModel" *ngIf="edit" class="form-control">
                  </div>
                </div>
                <div class="row mt-3" *ngIf="edit">
                  <div class="col-md-12">
                    <button class="btn btn-primary">Update Details</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12">
              <div class="card mb-4 mb-md-0">
                <div class="card-body table-responsive-product">
                  <table class="table cart-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Product</th>
                            <th scope="col">Stock </th>
                            <th scope="col">Price</th>
                            <th scope="col">Published</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          
                            <td class="product-thumbnail">
                                <a href="#"><img src="../assets/images/cat01.jpg" alt=""></a>
                            </td>
                            <td>Snapback in Black</td>
                            <td>2</td>
                            <td>$20.00</td>
                            <td>08/08/2022</td>
                        </tr>
                        <tr>
                            <td class="product-thumbnail">
                                <a href="#"><img src="../assets/images/cat01.jpg" alt=""></a>
                            </td>
                            <td>Sneakers in White</td>
                            <td>1</td>
                            <td>$149.00</td>
                            <td>08/08/2022</td>
                        </tr>
                    </tbody>
                </table>
      
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>
