import { Component, OnInit } from '@angular/core';
import { ApiService } from "../service/api.service";
import { CartService } from "../service/cart.service";
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})
export class ShippingComponent implements OnInit {
  public response : any = [];
  public cartInformation : any = [];
  public total:any = 0;

  public form : any = {
    first_name: '',
    last_name: '',
    street_address: '',
    city: '',
    state: '',
    zip: '',
    phone : '',
    email : '',
    notes : '',
  };

  public states : any = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
  ]

  constructor(private _snackBar: MatSnackBar, private Router:Router, private ApiService: ApiService, private CartService:CartService) { }

  ngOnInit(): void {
    this.getCartInformation();
  }

  getCartInformation = () =>{
    this.CartService.getProduct().subscribe((data:any)=>{
      this.cartInformation = data;
      this.getTotal();
    });
  }

  getTotal = () =>{
    this.total = 0;
    this.cartInformation.forEach((element, index) => {
      let price = (element.splprice * element.quantity)
      this.total+= price;
    });
  }

  onSubmit = () =>{
    let orderInformation = {
      userInformation : this.form,
      productInformation : this.cartInformation,
      totalPrice : this.total,
    };
    this.ApiService.postData('user/shipping', orderInformation).subscribe((data:any)=>{
      this.response = data;
      this.Router.navigate([`thank-you/${this.response.data._id}`]);
      this.openSnackBar(`Hii ${this.form.first_name} ${this.form.last_name} Your Order Has Been Successfull..`)
    }, error =>{
      this.openSnackBar(`Server error...`)
    });
  }

  openSnackBar(message) {
    this._snackBar.openFromComponent(AlertComponent, {
      duration: 5 * 1000,
      horizontalPosition : "end",
      verticalPosition : "top",
      data: {message, icon: 'fa-user'}
    });
  }

}
