
<div class="breadcrumbs">
    <div class="breadcrumbsection">
        <ul class="breadcrumb">
            <li><a class="m-link" routerLink="/">Home</a></li>
            <li><a class="m-link subcategory">Shipping</a></li>
          </ul>
    </div>
</div>
<div class="section">
    <div class="container">
        <div class="row g-4 g-lg-5">
            <div class="col-12 col-xl-8">
                <h5 class="fw-normal mb-4">Billing details</h5>
                <form #f="ngForm" (ngSubmit)="onSubmit()"> 
                    <div class="row g-3">
                        <div class="col-6">
                            <label class="required">First Name</label>
                            <input type="text" placeholder="First Name" name="first_name"
                            [(ngModel)]="form.first_name"
                            required
                            #first_name="ngModel">
                            <div class="error" *ngIf="first_name.errors && (first_name.dirty || first_name.touched)">
                                <div *ngIf="first_name.errors['required']">Name Field is required *</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="required">Last Name</label>
                            <input type="text" placeholder="Last Name" name="last_name"
                            [(ngModel)]="form.last_name"
                            required
                            #last_name="ngModel">
                            <div class="error" *ngIf="last_name.errors && (last_name.dirty || last_name.touched)">
                                <div *ngIf="last_name.errors['required']">Name Field is required *</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="required">Street address</label>
                            <input type="text" placeholder="Address" name="street_address"
                            [(ngModel)]="form.street_address"
                            required
                            #street_address="ngModel">
                            <div class="error" *ngIf="street_address.errors && (street_address.dirty || street_address.touched)">
                                <div *ngIf="street_address.errors['required']">Address Field is required *</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="required">Town / City</label>
                            <input type="text" placeholder="City" name="city"
                            [(ngModel)]="form.city"
                            required
                            #city="ngModel">
                            <div class="error" *ngIf="city.errors && (city.dirty || city.touched)">
                                <div *ngIf="city.errors['required']">City is required *</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="required">State</label>
                            <select class="custom-select w-100" name="state" [(ngModel)]="form.state" required #state="ngModel">
                                <option value="">Select</option>
                                <option value="{{ state }}" *ngFor="let state of states">{{ state }}</option>
                            </select>
                            <div class="error" *ngIf="state.errors && (state.dirty || state.touched)">
                                <div *ngIf="state.errors['required']">State Field is required *</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="required">ZIP</label>
                            <input type="text" placeholder="ZIP" name="zip"
                            [(ngModel)]="form.zip"
                            required
                            #zip="ngModel">
                            <div class="error" *ngIf="zip.errors && (zip.dirty || zip.touched)">
                                <div *ngIf="zip.errors['required']">ZIP Code is required *</div>
                            </div>
                        </div>

                        <div class="col-6">
                            <label class="required">Phone</label>
                            <input type="text" placeholder="Phone" name="phone"
                            [(ngModel)]="form.phone"
                            required
                            #phone="ngModel">
                            <div class="error" *ngIf="phone.errors && (phone.dirty || phone.touched)">
                                <div *ngIf="phone.errors['required']">Phone Number is required *</div>
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <label for="password">Email address</label>
                            <input type="text" placeholder="Email address*" name="email"
                            [(ngModel)]="form.email"
                            required
                            email="email"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                            #email="ngModel">
                            <div class="error" *ngIf="email.errors && (email.dirty || email.touched)">
                                <div *ngIf="email.errors['required']">Email ID is required</div>
                                <div *ngIf="email.errors['pattern']">email is not valid</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <label>Order notes (optional)</label>
                            <textarea [(ngModel)]="form.notes"
                            name="notes"
                            #notes="ngModel" placeholder="Notes about your order, eg. special notes for delivery."></textarea>
                        </div>
                    </div>
                    <button class="btn btn-primary" type="submit" [disabled]="f.invalid">Place Order</button>
                </form>
            </div>
            <div class="col-12 col-xl-4">
                <div class="bg-light-gray p-5">
                    <h5 class="fw-normal">Your order</h5>
                    <table class="table cart-totals">
                        <tbody>
                            <tr *ngFor="let cart of cartInformation; let i=index;">
                                <th scope="row">{{ cart.name }} x {{ cart.quantity }}</th>
                                <td>₹{{ cart.splprice * cart.quantity }}</td>
                            </tr>
                            <tr>
                                <th scope="row">Shipping</th>
                                <td>
                                    <div class="form-check">
                                        <label class="form-check-label" for="exampleRadios1">
                                            0
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Total</th>
                                <td>₹{{ total }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="button button-md button-dark button-fullwidth mt-3" (click)="onSubmit()"  [disabled]="f.invalid" href="#">Place order</button>
                </div>
            </div>
        </div><!-- end row -->
    </div><!-- end container -->
</div>