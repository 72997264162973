import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { ApiService } from "../service/api.service";
import { CartService } from "../service/cart.service"

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  public order : any = [];
  public response : any;
  public id:any;
  public user_name = "";
  public orderId = "";
  constructor(private CartService:CartService, private ActivatedRoute:ActivatedRoute, private ApiService:ApiService, private Router:Router) { }

  ngOnInit(): void {
    this.ActivatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
    });

    this.getOrderDetail();
  }

  getOrderDetail = () =>{
    this.ApiService.getData(`user/getsingleorder/${this.id}`).subscribe((data:any)=>{
        this.response = data;
        this.order = this.response.ShippingDetails[0];
        this.user_name = this.order.first_name + ' ' + this.order.last_name;
        this.orderId = this.order.orderDetails.orderId;
        this.CartService.removeAllCart();
    });
  }

}
 