<carousel [height]="heroBannerHeight" cellWidth="100%" cellsToShow="1" loop="true" cellsToScroll="1" autoplay="true"
    autoplayInterval="5000" pauseOnHover="false" dots="true">
    <div class="carousel-cell">
        <img src="assets/images/1.png" class="hero-banner-img">
    </div>
    <div class="carousel-cell">
        <img src="assets/images/2.png"   class="hero-banner-img">
    </div>
</carousel>
<!-- end Hero section -->

<!-- New Arrivals -->
<div class="section-lg">
    <div class="container">
        <div class="text-center margin-bottom-70">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <h2 class="font-weight-normal">New Arrivals</h2>
                    <p>Discover the latest trends and designs in the world of sarees at Studio Kanchie. We are thrilled to introduce our breathtaking New Arrivals collection, carefully curated to cater to your unique sense of style and elegance.</p>
                </div>
            </div>
        </div>
        <div class="row col-spacing-40">
            <div class="col-12 col-md-4" *ngFor="let product of newArrivals">
                <div class="product-box">
                    <div class="product-img">
                        <a routerLink="/single-product/{{ product._id }}">
                            <img src="{{ product.photo.secure_url }}" alt="" class="product-box-img">
                        </a>
                        <div class="product-badge-left">
                            <span class="font-small uppercase font-family-secondary font-weight-medium">New</span>
                        </div>
                    </div>
                    <div class="product-title">
                        <h6 class="font-weight-medium"><a routerLink="/single-product/{{ product._id }}">{{ product.name }}</a></h6>
                        <div class="price-and-cart">
                            <!-- <div class="price">
                                <span>₹ {{ product.splprice }}</span>
                            </div> -->
                            <div class="add-to-cart">
                                <a class="button-text-1" (click)="addToCart(product)" href="javascript::void(0)">Add to Cart</a>
                            </div>
                        </div>
                        <!-- <div class="add-to-wishlist">
                            <a href="#"><i class="far fa-heart"></i></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div><!-- end row -->
        <div class="text-center margin-top-70">
            <a class="button button-lg button-dark button-rounded" routerLink="/category-product/635bd3c6d0d666f43b30e046">View More</a>
        </div>
    </div>
</div>
<!-- end New Arrivals -->

<!-- Product Categories -->
<div class="section-lg">
    <div class="container">
        <div class="text-center margin-bottom-70">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <h2 class="font-weight-normal">Categories</h2>
                    <p>Indulge in timeless elegance. Explore handwoven masterpieces & contemporary designs. Elevate your charm with captivating colors & fabrics. Embrace sophistication with us.</p>
                </div>
            </div>
        </div>
        <div class="row col-spacing-40">
            <div class="col-12 col-md-4 col-lg-4 " *ngFor="let category of categories;let i = index;">
                <div class="fancy-box-3" *ngIf="i < 3">
                    <img src="assets/images/cat0{{ i+1 }}.jpg" alt="">
                    <div class="content">
                        <h5 class="font-weight-normal">{{ category.name }}</h5>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean massa.</p> -->
                    </div>
                    <div class="hover-content">
                        <a class="button button-lg button-outline-white-2" routerLink="/category-product/{{ category._id }}">Browse</a>
                    </div>
                </div>
            </div>
        </div><!-- end row -->
    </div>
</div><!-- end container-fluid -->
<!-- end Product Categories -->

<!-- Trending Items -->
<div class="section-lg">
    <div class="container">
        <div class="text-center margin-bottom-70">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <h2 class="font-weight-normal">Our Best Sellers</h2>
                    <p>Explore our Bestsellers Saree Collection and embrace the essence of timeless grace. Discover the allure of these sarees that have become favorites among fashion enthusiasts.</p>
                </div>
            </div><!-- end row -->
        </div>
        <carousel cellsToShow="{{itemsToShow}}" cellsToScroll="{{itemsToShow}}" dots="true" arrows="true" height="477">
            <div class="carousel-cell" *ngFor="let product of bestSellers">
                <!-- Carousel Item 1 -->
                <div class="product-box">
                    <div class="product-img">
                        <a href="javascript::void(0)">
                            <img src="{{ product.photo.secure_url }}" alt="" style=" width:100%; height:400px; background-color: #c3bdbd47;">
                        </a>
                    </div>
                    <div class="product-title">
                        <h6 class="font-weight-medium"><a href="javascript::void(0)">{{ product.name }}</a></h6>
                        <div class="price-and-cart">
                            <!-- <div class="price">
                                <del>₹{{ product.price }}</del>
                                <span>₹{{ product.splprice }}</span>
                            </div> -->
                            <div class="add-to-cart">
                                <a class="button-text-1" (click)="addToCart(product)" href="javascript::void(0)">Add to Cart</a>
                            </div>
                        </div>
                        <!-- <div class="add-to-wishlist">
                            <a href="javascript::void(0)"><i class="far fa-heart"></i></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </carousel><!-- end owl-carousel -->
        <div class="text-center margin-top-40">
            <a class="button button-lg button-gray button-rounded" routerLink="/category-product/635bd3c6d0d666f43b30e046" href="javascript::void(0)">View All</a>
        </div>
    </div><!-- end container -->
</div>
<!-- end Trending Items -->
<div class="section-lg padding-top-0">
    <div class="container">
        <div class="text-center margin-bottom-70">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <h2 class="font-weight-normal">Testimonial</h2>
                    <p>Thank you to all our wonderful customers for sharing their experiences with Studio Kanchana. Your kind words and support inspire us to continue delivering the best in saree fashion. We look forward to creating more cherished memories with you!</p>
                </div>
            </div><!-- end row -->
        </div>
        <carousel  cellsToShow="{{itemsToShow}}" cellsToScroll="{{itemsToShow}}" autoplay="true" dots="false" arrows="false" height="230">
            <div class="carousel-cell">
                <div class="testimonial-box">
                    <div class="quote">
                        <p>Perfect Shopping Experience! 🛍️ Studio Kanchana's website is user-friendly, making it effortless to browse and find the perfect saree. The delivery was quick, and the saree was even more beautiful in person. I'm thrilled with my purchase</p>
                    </div>
                    <div class="avatar">
                        <img src="assets/images/user.png" alt="">
                        <div>
                            <h6 class="font-weight-medium">Priya S</h6>
                            <span>Founder - Agency</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="testimonial-box">
                    <div class="quote">
                        <p>Highly Recommended! 🌈 I stumbled upon Studio Kanchana's Bestsellers collection and fell in love with their sarees. The designs are captivating, and the quality is top-notch. I can't wait to show off my new saree at the upcoming family gathering!</p>
                    </div>
                    <div class="avatar">
                        <img src="assets/images/user.png" alt="">
                        <div>
                            <h6 class="font-weight-medium"> Aditi K</h6>
                            <span>Founder - Agency</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="testimonial-box">
                    <div class="quote">
                        <p>Stunning Sarees, Excellent Service! ⭐ Studio Kanchana offers an amazing collection of sarees that cater to all tastes. The designs are unique and beautiful. I received my order promptly, and the customer support was fantastic. Highly recommend!</p>
                    </div>
                    <div class="avatar">
                        <img src="assets/images/user.png" alt="">
                        <div>
                            <h6 class="font-weight-medium">Aarti R.</h6>
                            <span>Founder - Agency</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="testimonial-box">
                    <div class="quote">
                        <p>Custom Saree Dream Come True! ✨ I wanted a one-of-a-kind saree for my sister's wedding, and Studio Kanchana delivered beyond my expectations. Their talented designers created a masterpiece that left everyone in awe. Thank you for making the day extra special!</p>
                    </div>
                    <div class="avatar">
                        <img src="assets/images/user.png" alt="">
                        <div>
                            <h6 class="font-weight-medium">Sneha P</h6>
                            <span>Founder - Agency</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="testimonial-box">
                    <div class="quote">
                        <p>Exquisite Designs, Ethical Values! 🌺 Studio Kanchana's sarees are not only beautiful but also crafted with ethical practices. I appreciate their commitment to sustainability. It's refreshing to support a brand that cares about the planet!</p>
                    </div>
                    <div class="avatar">
                        <img src="assets/images/user.png" alt="">
                        <div>
                            <h6 class="font-weight-medium">Divya T</h6>
                            <span>Founder - Agency</span>
                        </div>
                    </div>
                </div>
            </div>

        </carousel>
    </div>
</div>
