<div class="section">
    <div class="container">
        <div class="row col-spacing-50 align-items-center">
            <div class="col-12 col-lg-12">
                <h1 class="display-4 font-weight-light margin-bottom-20">Let's work together.</h1>
                <p class="font-weight-light font-large">We are delighted to hear from you! If you have any questions, concerns, or feedback, please feel free to get in touch with us using the contact information provided below.</p>
            </div>
        </div><!-- end row -->
    </div><!-- end container -->
</div>
	<!-- Services section -->
    <div class="section padding-top-0">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-5">
                    <h5 class="font-weight-medium margin-0">Phone:</h5>
                    <p>+65 96245471</p>
                    <h5 class="font-weight-medium margin-0">Email:</h5>
                    <p>kanchie56@gmail.com</p>
                    <h5 class="font-weight-medium margin-0">Address:</h5>
                    <p>28 Jalan Chengkek, Singapore, Singapore</p>
                </div>
                <div class="col-12 col-lg-7 text-lg-right">
                    <form>
                        <div class="form-row">
                            <div class="col-12 col-sm-6">
                                <input type="text" placeholder="Name">
                            </div>
                            <div class="col-12 col-sm-6">
                                <input type="email" placeholder="E-Mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 col-sm-12">
                                <input type="text" placeholder="Subject">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 col-sm-12">
                                <textarea placeholder="Message" id="message"></textarea>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 col-sm-12">
                                <button class="button button-lg button-rounded button-outline-dark" type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div><!-- end row -->
        </div><!-- end container -->
    </div>
    <!-- end Services section -->
