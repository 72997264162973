<div class="section">
	<div class="container">
		<div class="row">
			<!-- Blog Posts -->
			<div class="col-12 col-lg-12">
                <h4>Terms & Conditions</h4>
                <h6>General</h6>
                <p>1.1. The term "we," "our," or "us" refers to the small clothing business.</p>
                <p>1.2. The term "you" or "customer" refers to the user or purchaser of our products.</p>
                <p>1.3. The term "product" refers to any clothing item or accessory available for purchase on our website.</p>
                <h6>Product Information</h6>
                <p>2.1. We make every effort to provide accurate product descriptions, images, and information. However, we cannot guarantee that the product descriptions or other content on our website are error-free, complete, or current.</p>
                <p>2.2. The colors of the products may vary slightly due to differences in computer monitors, and we cannot be held responsible for such discrepancies.</p>
                <h6>Orders and Payments</h6>
                <p>3.1. By placing an order on our website, you are making an offer to purchase the products. All orders are subject to acceptance by us.</p>
                <p>3.2. We reserve the right to refuse or cancel any order at our discretion, including but not limited to situations where the product is out of stock, there are inaccuracies in product information or pricing, or there are suspicious or fraudulent activities related to the order.</p>
                <p>3.3. The prices of products are listed on our website and are subject to change without prior notice. All prices are in the local currency and include applicable taxes unless otherwise stated.</p>
                <p>3.4. Payment is required at the time of placing an order. We accept various payment methods, which will be specified on our website.</p>
                <h6>Shipping and Delivery</h6>
                <p>4.1. We will make every effort to process and ship your order promptly. However, we do not guarantee delivery dates and are not liable for delays beyond our control (e.g., shipping carrier delays, customs clearance, natural disasters, etc.).</p>
                <p>4.2. Shipping costs, if applicable, will be indicated during the checkout process and may vary based on your location and the selected shipping method.</p>
                <p>4.3. The risk of loss or damage to the products passes to you upon delivery.</p>
                <h6>Returns and Exchanges</h6>
                <p>5.1. We want you to be satisfied with your purchase. If you are not happy with a product, please contact us within 15days of receiving the order to request a return or exchange.</p>
                <p>5.2. Products must be returned in their original condition and packaging, and you will be responsible for any return shipping costs unless the product is faulty or damaged.</p>
                <p>5.3. We reserve the right to refuse a return or exchange if the product shows signs of use, damage, or alteration.</p>
                <h6>Intellectual Property</h6>
                <p>6.1. All content on our website, including but not limited to text, images, logos, and designs, is our intellectual property and may not be used or reproduced without our express written permission.</p>
            </div>
        </div>
    </div>
</div>