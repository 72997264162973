<div class="container">
    <div class="row">
        <div class="col-md-12 text-center mt-4 pb-4">
            <p>Hi <b>{{ user_name }}</b> your Order Id is <b>{{ orderId }}</b> has successfully placed with us.</p>
            <p>Thank you for Shopping with us.</p>
        </div>
    </div>
    <a routerLink="/">
        <img src="assets/images/thankyou.jpg" alt="">
    </a>
</div>