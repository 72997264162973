import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../alert/alert.component';
import { ApiService } from "../service/api.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from "../service/token.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public form : any = {
    name: '',
    email: '',
    mobile: '',
    password: '',
    role: 2,
  };
  public token : any = "";
  constructor(private TokenService:TokenService, private _snackBar: MatSnackBar, private router: Router, private ApiService: ApiService, private ActivatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.getToken();
    if(this.token != null){
      this.router.navigate(['/']);
    }
  }

  onSubmit(): void {
    this.ApiService.post('enduser/signup', this.form).subscribe((data:any)=>{
      this.openSnackBar(`Hii ${this.form.name}, You are now registered with Studiokanchi....`)
      this.router.navigate(['/login']);
    });
  }

  openSnackBar(message) {
    this._snackBar.openFromComponent(AlertComponent, {
      duration: 5 * 1000,
      horizontalPosition : "end",
      verticalPosition : "top",
      data: {message}
    });
  }

  getToken = () =>{
    this.TokenService.getToken().subscribe((data:any)=>{
      this.token = data;
    });
  }

}
