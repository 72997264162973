import { Component, OnInit } from '@angular/core';
import { ApiService } from "../service/api.service";
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../alert/alert.component';
import { CartService } from "../service/cart.service";

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.css']
})
export class SingleProductComponent implements OnInit {
  public product_id : string;
  public product : any = [];
  public response : any = [];
  public bigimage : string;
  public newArrivals : any = [];
  public attributes : any = [];
  public updatedAttributes : any = [];
  public selected_attributes : any = {
    attribute_name : '',
    attribute_value : '',
  };
  public category : any = [];
  public subcategory : any = [];
  public cartInformation: any;
  public cartQuantity :number = 1;
  constructor(private CartService:CartService, private _snackBar: MatSnackBar,private router: Router, private ApiService: ApiService, private ActivatedRoute:ActivatedRoute) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void { 
    this.newArrival();
    this.product_id = this.ActivatedRoute.snapshot.paramMap.get('id');
    this.ApiService.getData(`product/${this.product_id}`).subscribe((data:any)=>{
      this.response = data;
      this.product = this.response.product;
      this.product.quantity = 1;
      this.category = this.product.category;
      this.subcategory = this.product.subcategory;
      this.bigimage = this.product.photo.secure_url;
      this.product.photos.unshift(this.product.photo);
      this.attributes = this.response.attributes;
      this.arrangeAttributes(); 
    });
  }

  changeImage = (image:string) =>{
    this.bigimage = image;
  }

  newArrival = () =>{
    this.ApiService.getData('get_new_arrival').subscribe((data:any)=>{
      this.response = data;
      this.newArrivals = this.response.newArrivalProduct;
    });
  }

  arrangeAttributes = () =>{
    if(this.attributes != ''){
      this.attributes.forEach((attr, attr_index) => {
        
        let check = 0;
        this.updatedAttributes.forEach(test => {
          if(test.attribute_name == attr.attribute_name){
            check = 1; // the attribue is already exist
          }
        });
        if(check == 0){ // If the attribue is not already exist
          this.updatedAttributes[attr_index] = {attribute_name : attr.attribute_name, attribute_values : []};

          // Adding Attribute Values...
          this.attributes.forEach((subAttr) => {
            if(subAttr.attribute_name == attr.attribute_name){
              this.updatedAttributes[attr_index].attribute_values.push(subAttr.attribute_value);
            }
          });
        }

      });
       // Removing the empty objects
       this.updatedAttributes = this.updatedAttributes.filter(value => Object.keys(value).length !== 0);
    }
    
  }

  changeAttribute = (attribute_name, attribute_value:any) =>{
    this.selected_attributes.attribute_name = attribute_name;
    this.selected_attributes.attribute_value = attribute_value;
    this.product.attributes = this.selected_attributes;

    this.ApiService.getData(`product/get-products-by-attribute/${this.product_id}/${attribute_name}/${attribute_value}`).subscribe((data:any)=>{
      this.response = data;
      let attributes = this.response.data;
      if(attributes.photos != ''){
        this.product.photos = attributes.photos;
        this.bigimage = attributes.photos[0].secure_url;
      }
    });
  }

  addToCart = () =>{
    this.CartService.addToCart(this.product, this.cartQuantity);
    this.openSnackBar('Cart Updated !');
  }

  openSnackBar(message) {
    this._snackBar.openFromComponent(AlertComponent, {
      duration: 5 * 1000,
      data: {message}
    });
  }

  getCartInformation = () =>{
    this.CartService.getProduct().subscribe((data:any)=>{
      this.cartInformation = data;
    });
  }

  increaseQuantity = () =>{
    this.cartQuantity++;
  }

  decreaseQuantity = () =>{
    if(this.cartQuantity > 1) this.cartQuantity--
  }

}
