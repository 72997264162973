import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from "./service/api.service";
import { CartService } from "./service/cart.service";
import { TokenService } from "./service/token.service";
import { Router,NavigationEnd  } from "@angular/router";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public categories : any = [];
  public response : any = [];
  public cartInformation : any = [];
  public token : any = "";
  public position : number = 300;
  constructor( private breakpointObserver: BreakpointObserver, private Router:Router, private TokenService:TokenService, private ApiService: ApiService,  private CartService:CartService){}

  ngOnInit(): void {
    this.allCategories();
    this.getCartInformation();
    this.getToken();
    console.log(this.token, 'hellow');
    this.Router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.onRouteChange(); // Call your function here
      });
      
  }

  onRouteChange() {
   this.position= 100;
   this.breakpointObserver.observe([
    `(max-width: 460px)`,
    `(max-width: 768px)`
  ]).subscribe(result => {
    
    if (result.breakpoints['(max-width: 460px)']) {
      console.log('result 460px', result);
      this.position = 300; 
      
       // Show 1 item for screens <= 460px
    } else if (result.breakpoints['(max-width: 768px)']) {
      console.log('result 768px', result);
      this.position = 300;  
      
    } else {
      console.log('result', result);
      this.position = 0; 
      
    }
  });
    // Your custom logic here
  }
  title = 'studiokanchie';

  // Carousel 
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  allCategories = () =>{
    try {
        this.ApiService.getData('category').subscribe((data:any)=>{
          this.response = data;
          this.categories = this.response.category;
        });
    } catch (error) {
        console.log(error);
    }
  }

  getCartInformation = () =>{
    this.CartService.getProduct().subscribe((data:any)=>{
      this.cartInformation = data;
      console.log(this.cartInformation);
    });
  }

  getToken = () =>{
    this.TokenService.getToken().subscribe((data:any)=>{
      this.token = data;
    });
  }
   
   menutoggle(isOpen : boolean){
    isOpen ? this.position = 0 : this.position = 300;
    
   }
  
}
