import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  tokenData : any = JSON.parse(localStorage.getItem('token')!);
  token : any = new BehaviorSubject<any>(this.tokenData);
  constructor() { }

  getToken = () =>{
    return this.token.asObservable();
  }

  setToken = (token:any) =>{
    this.tokenData = token;
    localStorage.setItem('token', JSON.stringify(token));
    this.token.next(this.tokenData);  
  }

  removeToken = () =>{
    localStorage.removeItem('token');
    this.tokenData = '';
    this.token.next(this.tokenData);
  }

}
