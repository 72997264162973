<div class="section-lg pt-5 pb-5">
    <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="font-weight-normal w-100">A Play in Contrasts</h2>
                    <div class="heading-divider"></div>
                    <p>A gorgeous set of Kadiyal classics! The traditional Kadiyal technique involves changes in the warp and weft to weave the borders and aanchal in colours that are different from the body. These are time-honoured pieces that celebrate our rich, vivid heritage!</p>
                </div>
            </div>
    </div>
</div>
<div class="container">
        <div class="margin-bottom-70">
            <div class="row">
                <div class="col-md-3">
                    <div class="filter-group">
                        <h4 style="border-bottom: 0px; margin-bottom: 3px;">Search</h4>
                        <div class="scroll-content">
                            <ul class="nav-color ">
                                <input type="text" id="searchInput" placeholder="Search..">
                            </ul>
                        </div>
                    </div>
                    <div class="filter-group">
                        <h4>Refine</h4>
                        <a class="filter-clear" href="#">view all</a>
                    </div>
                    <div class="filter-group">
                        <h4>Color</h4>
                        <div class="scroll-content">
                            <ul class="nav-color ">
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Black</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Blue</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Brown</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Green</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Grey</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Magenta</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Pink</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Purple</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Red</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>White</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Yellow</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="filter-group">
                        <h4>Fabric</h4>
                        <div class="scroll-content">
                            <ul class="nav-color ">
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Satin Silk</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Katan Tissue</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Katan Silk</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="filter-group">
                        <h4>Type</h4>
                        <div class="scroll-content">
                            <ul class="nav-color ">
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Lehengas</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="filter-group">
                        <h4>Price</h4>
                        <div class="scroll-content">
                            <ul class="nav-color ">
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Over ₹40,000</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="filter-group">
                        <h4>Pattern</h4>
                        <div class="scroll-content">
                            <ul class="nav-color ">
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Floral</a></li>
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Patola</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="filter-group">
                        <h4>Availability</h4>
                        <div class="scroll-content">
                            <ul class="nav-color ">
                                <li><a href="#"><i class="far fa-circle check-icon"></i>Pre-Order</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="row col-spacing-40">
                        <div class="col-12 col-md-4">
                            <div class="product-box">
                                <div class="product-img">
                                    <a routerLink="/single-product">
                                        <img src="assets/images/prd01.jpg" alt="">
                                        <img src="assets/images/prd01-01.jpg" alt="">
                                    </a>
                                    <div class="product-badge-left">
                                        <span class="font-small uppercase font-family-secondary font-weight-medium">New</span>
                                    </div>
                                </div>
                                <div class="product-title">
                                    <h6 class="font-weight-medium"><a href="#">Product title</a></h6>
                                    <div class="price-and-cart">
                                        <div class="price">
                                            <span>$98</span>
                                        </div>
                                        <div class="add-to-cart">
                                            <a class="button-text-1" href="#">Add to Cart</a>
                                        </div>
                                    </div>
                                    <div class="add-to-wishlist">
                                        <a href="#"><i class="far fa-heart"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="product-box">
                                <div class="product-img">
                                    <a routerLink="/single-product">
                                        <img src="assets/images/prd02.jpg" alt="">
                                        <img src="assets/images/prd02-02.jpg" alt="">
                                    </a>
                                    <div class="product-badge-left">
                                        <span class="font-small uppercase font-family-secondary font-weight-medium">New</span>
                                    </div>
                                </div>
                                <div class="product-title">
                                    <h6 class="font-weight-medium"><a href="#">Product title</a></h6>
                                    <div class="price-and-cart">
                                        <div class="price">
                                            <span>$98</span>
                                        </div>
                                        <div class="add-to-cart">
                                            <a class="button-text-1" href="#">Add to Cart</a>
                                        </div>
                                    </div>
                                    <div class="add-to-wishlist">
                                        <a href="#"><i class="far fa-heart"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="product-box">
                                <div class="product-img">
                                    <a routerLink="/single-product">
                                        <img src="assets/images/prd03.jpg" alt="">
                                        <img src="assets/images/prd03-03.jpg" alt="">
                                    </a>
                                    <div class="product-badge-left">
                                        <span class="font-small uppercase font-family-secondary font-weight-medium">New</span>
                                    </div>
                                </div>
                                <div class="product-title">
                                    <h6 class="font-weight-medium"><a href="#">Product title</a></h6>
                                    <div class="price-and-cart">
                                        <div class="price">
                                            <span>$98</span>
                                        </div>
                                        <div class="add-to-cart">
                                            <a class="button-text-1" href="#">Add to Cart</a>
                                        </div>
                                    </div>
                                    <div class="add-to-wishlist">
                                        <a href="#"><i class="far fa-heart"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!-- end row -->
                </div>
            </div>
        </div>
</div>