import { Component, ElementRef, ViewChild,HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from "./service/api.service";
import { CartService } from "./service/cart.service";
import { TokenService } from "./service/token.service";
import { Router,NavigationEnd  } from "@angular/router";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter } from 'rxjs/operators';


export interface Subcategory {
  _id: string;
  name: string;
  category: Category;
  createdAt: string;
  __v: number;
}

export interface Category {
  photo: Photo;
  _id: string;
  name: string;
  createdAt: string;
  __v: number;
}

export interface Photo {
  id: string;
  secure_url: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})



export class AppComponent {

  public categories : any = [];
  public response : any = [];
  public cartInformation : any = [];
  public token : any = "";
  public position : number = 300;
  public subcategory : Subcategory[] = [];
  public   isDropdownOpen = false;
  constructor( private breakpointObserver: BreakpointObserver, private Router:Router, private TokenService:TokenService, private ApiService: ApiService,  private CartService:CartService){}

  ngOnInit(): void {
    this.allCategories();
    this.getCartInformation();
    this.getToken();
    this.getAllSubcategory();
    this.Router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.onRouteChange(); // Call your function here
      });
    this.Router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
         this.isDropdownOpen = false;
      }
    });
      
  }

  onRouteChange() {
   this.position= 100;
   this.breakpointObserver.observe([
    `(max-width: 460px)`,
    `(max-width: 768px)`
  ]).subscribe(result => {
    
    if (result.breakpoints['(max-width: 460px)']) {
      console.log('result 460px', result);
      this.position = 300; 
      
       // Show 1 item for screens <= 460px
    } else if (result.breakpoints['(max-width: 768px)']) {
      console.log('result 768px', result);
      this.position = 300;  
      
    } else {
      console.log('result', result);
      this.position = 0; 
      
    }
  });
    // Your custom logic here
  }
  title = 'studiokanchie';

  // Carousel 
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  allCategories = () =>{
    try {
        this.ApiService.getData('category').subscribe((data:any)=>{
          this.response = data;
          this.categories = this.response.category;
        });
    } catch (error) {
        console.log(error);
    }
  }
  getAllSubcategory = ()=>{
    try {
      this.ApiService.getData('subcategory').subscribe((data:any)=>{
        this.response = data;
        this.subcategory = this.response.subcategory;
        console.log(this.subcategory);
      });
  } catch (error) {
      console.log(error);
  }
  }

  getCartInformation = () =>{
    this.CartService.getProduct().subscribe((data:any)=>{
      this.cartInformation = data;
      console.log(this.cartInformation);
    });
  }

  getToken = () =>{
    this.TokenService.getToken().subscribe((data:any)=>{
      this.token = data;
    });
  }
   
   menutoggle(isOpen : boolean){
    isOpen ? this.position = 0 : this.position = 300;
    
   }


  toggleDropdown(event: Event): void {
      event.stopPropagation(); // Prevent closing parent menus
      this.isDropdownOpen = !this.isDropdownOpen;
  }

  @ViewChild('dropdownMenu', { static: false }) dropdownMenu: ElementRef | undefined;



  // Close the dropdown if clicked outside
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.isDropdownOpen && this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }
  
  
}
