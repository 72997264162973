<div class="section">
	<div class="container">
		<div class="row">
			<!-- Blog Posts -->
			<div class="col-12 col-lg-12">
                <h4>Privacy Policy</h4>
                <p>STUDIO KANCHANA is dedicated to protecting the privacy of our customers and website visitors. This Privacy Policy explains how we collect, use, and safeguard your personal information. By accessing our website or using our services, you agree to the practices described in this policy.</p>
                <h6>Information We Collect:</h6>
                <p>We may collect the following types of personal information:</p>
                    <ul>
                        <li>Name</li>
                        <li>Contact information, including email address and phone number</li>
                        <li>Address and location data for order delivery</li>
                        <li>Payment and billing information for processing transactions</li>
                        <li>Measurements and preferences for customized saree designs</li>
                        <li>Website usage data and cookies to enhance user experience</li>
                    </ul>
                <h6>How We Use Your Information:</h6>
                <p>We use your personal information for the following purposes:</p>
                <ul>
                    <li>To process and fulfill your saree orders and requests</li>
                    <li>To communicate with you regarding order status, product inquiries, and promotions</li>
                    <li>To provide personalized saree design recommendations and services</li>
                    <li>To improve our products and services based on customer feedback</li>
                    <li>To send occasional newsletters and marketing emails (you can opt-out anytime)</li>
                    <li>To conduct market research and understand customer preferences</li>
                    <li>To protect our website and customers from fraud or unauthorized access</li>
                    <li>To comply with legal obligations and resolve disputes</li>
                </ul>
                <h6>Data Security:</h6>
                <p>We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage method can be guaranteed to be 100% secure. Therefore, we cannot ensure or warrant the security of any information you transmit to us, and you do so at your own risk.</p>
                <h6>Cookies and Similar Technologies:</h6>
                <p>Our website may use cookies and similar technologies to enhance your browsing experience and gather information about how you interact with our site. You can manage your preferences for cookies through your browser settings.</p>
                <h6>Third-Party Service Providers:</h6>
                <p>We may share your personal information with trusted third-party service providers to facilitate order processing, delivery, and other business-related services. These providers are bound by confidentiality agreements and are not allowed to use your information for any other purpose.
                </p>
                <h6>Your Choices:</h6>
                <p>You have the right to access, correct, or delete your personal information. If you wish to exercise any of these rights or have concerns about your data, please contact us using the information provided below.</p>
                <h6>Changes to this Privacy Policy:</h6>
                <p>We reserve the right to update this Privacy Policy at any time without prior notice. Any changes will be posted on this page, and the revised policy will be effective as of the "Effective Date" above.
                </p>
                <h6>Contact Us:</h6>
                <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please email us at kanchie56@gmail.com</p>
            </div>
        </div>
    </div>
</div>