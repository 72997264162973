<div class="breadcrumbs">
    <div class="breadcrumbsection">
        <ul class="breadcrumb">
            <li><a class="m-link" routerLink="/">Home</a></li>
            <li><a class="m-link subcategory">Register</a></li>
          </ul>
    </div>
</div>
<div class="section-lg">
    <div class="container">
        <div class="row col-spacing-50 justify-content-md-center">
            <div class="col-md-8 col-sm-12 login-form-background">
                  <form name="form" #f="ngForm" (ngSubmit)="onSubmit()">
                    <h2>Create Account</h2>
                    <div class="row">
                        <div class="col-md-6">
                            <label for="name">Name</label>
                            <input type="text" placeholder="First name" name="name"
                            [(ngModel)]="form.name"
                            required
                            minlength="6"
                            maxlength="20"
                            #name="ngModel">
                            <div class="error" *ngIf="name.errors && (name.dirty || name.touched)">
                                <div *ngIf="name.errors['required']">Name is required</div>
                                <div *ngIf="name.errors['minlength']">Minimum 6 characters required</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="password">Email address</label>
                            <input type="text" placeholder="Email address*" name="email"
                            [(ngModel)]="form.email"
                            required
                            email="email"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                            #email="ngModel">
                            <div class="error" *ngIf="email.errors && (email.dirty || email.touched)">
                                <div *ngIf="email.errors['required']">Email ID is required</div>
                                <div *ngIf="email.errors['pattern']">email is not valid</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="password">Mobile Number</label>
                            <input type="text" placeholder="Mobile Number*" name="mobile"
                            [(ngModel)]="form.mobile"
                            required
                            minlength="8"
                            #mobile="ngModel">
                            <div class="error" *ngIf="mobile.errors && (mobile.dirty || mobile.touched)">
                                <div *ngIf="mobile.errors['required']">Mobile Number is required</div>
                                <div *ngIf="mobile.errors['minlength']">Minimum 8 numbers required</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="password">Password*</label>
                            <input type="password" placeholder="Password*" name="password"
                            [(ngModel)]="form.password"
                            required
                            minlength="8"
                            #password="ngModel">
                            <div class="error" *ngIf="password.errors && (password.dirty || password.touched)">
                                <div *ngIf="password.errors['required']">Password is required</div>
                                <div *ngIf="password.errors['minlength']">Minimum 8 numbers required</div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button [disabled]="f.invalid" type="submit" class="btn btn-primary">Sign Up</button>
                            <a routerLink="/login" class="btn btn-secondary ml-2">Go to Login</a>
                        </div>
                    </div>
                  </form>
            </div>
        </div>
    </div>
</div>
