import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private Router:Router, private _snackBar: MatSnackBar,){
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      if(!localStorage.getItem('token')){
        this.openSnackBar(`Hii User, You are not logged in, Please login to proceed...`)
        this.Router.navigate(['/login']);
      }
      return true;
  }

  openSnackBar(message) {
    this._snackBar.openFromComponent(AlertComponent, {
      duration: 5 * 1000,
      horizontalPosition : "end",
      verticalPosition : "top",
      data: {message, icon : 'fa-user'}
    });
  }
  
}
