
<!-- <div class="spinner">
    <mat-spinner *ngIf="spinner" class="spinner"></mat-spinner>
</div> -->
<div class="section-lg pt-5 pb-5" *ngIf="products.length == 0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <h2 class="font-weight-normal w-100" style="padding: 23px;">{{ notFoundMessage }}</h2>
            </div>
        </div>
    </div>
</div>

<div class="section-lg pt-5 pb-5" *ngIf="products.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="font-weight-normal w-100">A Play in Contrasts</h2>
                <div class="heading-divider"></div>
                <p>A gorgeous set of Kadiyal classics! The traditional Kadiyal technique involves changes in the warp and weft to weave the borders and aanchal in colours that are different from the body. These are time-honoured pieces that celebrate our rich, vivid heritage!</p>
            </div>
        </div>
    </div>
</div>
<div class="container" *ngIf="products.length > 0">
        <div class="margin-bottom-70">
            <div class="row">
                <div class="col-md-3">
                    <!-- <div class="filter-group">
                        <h4 style="border-bottom: 0px; margin-bottom: 3px;">Search</h4>
                        <div class="scroll-content">
                            <ul class="nav-color ">
                                <input type="text" id="searchInput" placeholder="Search..">
                            </ul>
                        </div>
                    </div> -->
                    <div class="filter-group">
                        <h4>Filter</h4>
                        <a class="filter-clear" (click)="removeFilter()" href="javascript::void(0)">Remove Filter</a>
                    </div>
                    <div class="filter-group" *ngFor="let attr of updatedAttributes">
                        <h4>{{ attr.attribute_name | titlecase }}</h4>
                        <div class="scroll-content">
                            <ul class="nav-color" *ngIf="attr.attribute_values.length > 0">
                                <li *ngFor="let value of attr.attribute_values">
                                    <a href="javascript::void(0)">
                                        <!-- <i class="far fa-circle check-icon"></i> -->
                                        <input type="radio" name="attributes" [value]="value" [(ngModel)]="selected_attribute" (change)="getAttributesValue()">
                                        {{ value | titlecase }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9" style="margin-bottom: 0px;">
                    <div class="row col-spacing-40">
                        <div class="col-12 col-md-4" *ngFor="let product of products">
                            <div class="product-box">
                                <div class="product-img">
                                    <a routerLink="/single-product/{{ product._id }}">
                                        <img src="{{ product.photo.secure_url }}" style="height:300px; width: 100%;" alt="">
                                    </a>
                                    <div class="product-badge-left">
                                        <span class="font-small uppercase font-family-secondary font-weight-medium">New</span>
                                    </div>
                                </div>
                                <div class="product-title">
                                    <h6 class="font-weight-medium">
                                        <a routerLink="/single-product/{{ product._id }}">{{ product.name }}</a>
                                    </h6>
                                    <!-- <div class="price-and-cart">
                                        <div class="price">
                                            <span>₹{{ product.splprice }}</span>
                                        </div>
                                        <div class="add-to-cart">
                                            <a class="button-text-1" (click)="addToCart(product)">Add to Cart</a>
                                        </div>
                                    </div>  -->
                                    <div class="add-to-wishlist">
                                        <a href="#"><i class="far fa-heart"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!-- end row -->
                    <nav aria-label="Page navigation example" style="margin-top:30px;"> 
                        <ul class="pagination" *ngIf="number_of_column_to_show > 1" style="display: flex; flex-wrap: wrap;">
                          <li class="" *ngIf="page > 1">
                            <a class="page-link" (click)="previousPage()">
                                <i class="fa fa-chevron-left"></i>
                            </a>
                          </li>
                          <li class="" *ngFor="let i of [].constructor(number_of_column_to_show); let number = index;">
                            <a class="page-link {{ page == (number + 1) ? 'active' : '' }}" (click)="changePage(number + 1)">{{ number + 1 }}</a>
                          </li>
                          <li class="" *ngIf="page != number_of_column_to_show">
                            <a class="page-link" (click)="nextPage()">
                                <i class="fa fa-chevron-right"></i>
                            </a>
                          </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
</div>

