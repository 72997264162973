<div class="breadcrumbs">
    <div class="breadcrumbsection">
        <ul class="breadcrumb">
            <li><a class="m-link" routerLink="/">Home</a></li>
            <li><a class="m-link subcategory">Cart</a></li>
          </ul>
    </div>
</div>
<div class="section-lg">
    <div class="container">
        <div class="row col-spacing-50" *ngIf="cartInformation.length > 0">
            <div class="col-12 col-xl-8">
                <table class="table cart-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Image</th>
                            <th scope="col">Product</th>
                            <!-- <th scope="col">Price</th> -->
                            <th scope="col">Quantity</th>
                            <!-- <th scope="col">Subtotal</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cart of cartInformation; let i = index;">
                            <th scope="row">
                                <a (click)="removeCartItem(cart)">x</a>
                            </th>
                            <td class="product-thumbnail">
                                <a >
                                    <img src="{{ cart.photo.secure_url }}" class="image-cart" alt="">
                                </a>
                            </td>
                            <td>
                                <span class="product-name">{{ cart.name }}</span>
                                <small class="attributes" *ngIf="cart.attributes">
                                    ({{ cart.attributes.attribute_name }},
                                    {{ cart.attributes.attribute_value }})
                                </small>
                            </td>
                            <!-- <td>₹{{ cart.splprice }}</td> -->
                            <td>
                                <form class="product-quantity">
                                    <div class="qnt">
                                        <input type="number" disabled id="quantity" min="1" max="10" readonly value="{{ cart.quantity }}">
                                    </div>
                                </form>
                            </td>
                            <!-- <td>
                               ₹{{ cart.splprice * cart.quantity }}
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 col-xl-4">
                <div class="bg-light-gray padding-40">
                    <!-- <h5 class="font-weight-normal">Cart totals</h5> -->
                    <table class="table cart-totals">
                        <tbody>
                            <!-- <tr>
                                <th scope="row">Subtotal</th>
                                <td>₹{{ total }}</td>
                            </tr> -->
                            <!-- <tr>
                                <th scope="row">Shipping</th>
                                <td> ₹0 -->
                                    <!-- <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                                        <label class="form-check-label" for="exampleRadios1">
                                        Flat rate: $14.00
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                        <label class="form-check-label" for="exampleRadios2">
                                        Free shipping
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3">
                                        <label class="form-check-label" for="exampleRadios3">
                                        Local pickup
                                        </label>
                                    </div> -->
                                <!-- </td>
                            </tr> -->
                            <!-- <tr>
                                <th scope="row">Total</th>
                                <td>
                                    <span style="font-weight: bold;">₹{{ total }}</span>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                    <a class="button button-md button-dark button-fullwidth margin-top-20" routerLink="/shipping">Proceed</a>
                </div>
            </div>
        </div><!-- end row -->
        <div class="row justify-content-center notfound" *ngIf="cartInformation.length == 0">
            <div class="col-2">
                <div class="message">
                    No Item Found In Cart
                </div>
                <div class="back">
                    <button class="btn btn-primary" routerLink="/">Go Back</button>
                </div>
            </div>
        </div>
    </div><!-- end container -->
</div>