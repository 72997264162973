import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { CartService } from "../service/cart.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../alert/alert.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public newArrivals : any = [];
  public bestSellers  : any = [];
  public response : any = [];
  public cartInformation : any = [];
  public categories:any = [];
  public itemsToShow:number=3;
  public heroBannerHeight: number = 560;


  constructor(private breakpointObserver: BreakpointObserver,private _snackBar: MatSnackBar, private ApiService: ApiService, private CartService:CartService){}

  ngOnInit(): void {
    this.newArrival();
    this.bestSeller();
    this.getCartInformation();
    this.Category();
    this.breakpointObserver.observe([
      `(max-width: 460px)`,
      `(max-width: 768px)`
    ]).subscribe(result => {
      
      if (result.breakpoints['(max-width: 460px)']) {
        console.log('result 460px', result);
        this.itemsToShow = 1; 
        this.heroBannerHeight = 200;
         // Show 1 item for screens <= 460px
      } else if (result.breakpoints['(max-width: 768px)']) {
        console.log('result 768px', result);
        this.itemsToShow = 2;  
        this.heroBannerHeight = 300;
        console.log(this.itemsToShow);
      } else {
        console.log('result', result);
        this.itemsToShow = 3; 
        this.heroBannerHeight = 560;
        console.log(this.itemsToShow);
      }
    });
    
  }

  getCartInformation = () =>{
    this.CartService.getProduct().subscribe((data:any)=>{
      console.log(data);
    });
  }

  newArrival = () =>{
    this.ApiService.getData('get_new_arrival').subscribe((data:any)=>{
      this.response = data;
      this.newArrivals = this.response.newArrivalProduct;
    });
  }
  Category = () =>{
    this.ApiService.getData('category').subscribe((data:any)=>{
      this.response = data;
      this.categories = this.response.category;
    });
  }

  addToCart = (product:any) =>{
    this.CartService.addToCart(product);
    this.openSnackBar('Cart Updated !');
  }

  openSnackBar(message) {
    this._snackBar.openFromComponent(AlertComponent, {
      duration: 5 * 1000,
      data: {message}
    });
  }

  bestSeller = () =>{
    this.ApiService.getData('get_best_seller').subscribe((data:any)=>{
      this.response = data;
      this.bestSellers = this.response.bestSellerProducts;
    });
  }


}
