<div class="section">
    <div class="container">
        <div class="row col-spacing-50 align-items-center">
            <div class="col-12 col-lg-6">
                <div class="box-shadow-with-hover">
                    <img src="assets/images/our-story.jpg" alt="">
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <h2 class="font-weight-light">An ethnic design Studio one of a kind in Singapore.</h2>
                <div class="margin-top-20">
                    <p>We bring varied handcrafted and handwoven sarees to cater women of all ages.  Our designs our contemporary with a dash of tradition and style .</p>
                </div>
            </div>
        </div><!-- end row -->
    </div><!-- end container -->
</div>
	<!-- Services section -->
    <div class="section padding-top-0">
        <div class="container text-center">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <div class="f-box style-1">
                        <div class="f-box-icon">
                            <i class="ti-star"></i>
                        </div>
                        <h5 class="font-weight-normal">Behind the name</h5>
                        <p>Studio Kanchana‘s............. passion for sarees and desire to support handlooms and weavers.</p>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="f-box style-1">
                        <div class="f-box-icon">
                            <i class="fas fa-pencil-ruler"></i>
                        </div>
                        <h5 class="font-weight-normal">Design Philosphy</h5>
                        <p>With great care and love we handpick only the finest Sarees to suit every occasion for our clients.</p>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="f-box style-1">
                        <div class="f-box-icon">
                            <i class="fas fa-history"></i>
                        </div>
                        <h5 class="font-weight-normal">Our Journey</h5>
                        <p>As a banking professional residing in Singapore, a culturally diverse nation, I have consistently received admiration {{isOpen?'':'...'}}<span *ngIf="isOpen">for my choice of wearing sarees to the workplace. I have always received a significant number of inquiries. This inspired me to establish a Studio after resigning from the bank.
                          I have had the privilege of embarking on a valuable learning journey since 2017, exploring the intricate world of textiles, weaves, and designs originating from various states across India. I derive great satisfaction from engaging in direct interactions with my weaver and manufacturing units.</span><a (click)="readMore()" style="color: blue; cursor: pointer;">{{isOpen ? "Read less" : "Read More"}}</a></p>
                    </div>
                </div>
            </div><!-- end row -->
        </div><!-- end container -->
    </div>
    <!-- end Services section -->
