import { Component, OnInit } from '@angular/core';
import { ApiService } from "../service/api.service";
import { CartService } from "../service/cart.service";
import { TokenService } from "../service/token.service";
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public response : any = [];
  public cartInformation : any = [];
  public token : any;
  public edit:boolean = false;
  public spinner : boolean = false;
  
  constructor(private _snackBar: MatSnackBar, private Router:Router, private TokenService:TokenService, private ApiService: ApiService, private CartService:CartService) { }

  public user:any = [];
  public userForm = {
    name : '',
    email : '',
    number : '',
    address : ''
  }


  ngOnInit(): void {
    this.getToken();
    this.getUserDetails();
  }

  getToken = () =>{
    this.TokenService.getToken().subscribe((data:any)=>{
      this.token = data;
      console.log(this.token);
    });
  }

  logout = () =>{
    let conf = confirm("Are you sure you want to logout ?");
    if(conf){
      if(this.token != ""){
        this.TokenService.removeToken();
      }
      this.Router.navigate(['/']);
    }
  }

  changeEditStatus = () =>{
    this.edit = !this.edit;
  }

  getUserDetails = () =>{
    let email = JSON.parse(localStorage.getItem('email'));
    this.ApiService.getData(`enduser/get-single-user/${email}`).subscribe((data:any)=>{
      this.response = data;
      this.user = this.response.user;
      if(this.user != ""){
        this.userForm.name = this.user.name;
        this.userForm.email = this.user.email;
        this.userForm.number = this.user.mobile;
        this.userForm.address = this.user.address;
      }
    });
  }

  onSubmit = () =>{
    this.spinner = true;
    this.ApiService.postData(`user/updateprofile/${this.user._id}`, this.userForm)
    .subscribe((data:any)=>{
      this.response = data;
      this.spinner = false;
      this.openSnackBar(`Dear ${this.response.user.name}, Your Profile Is Now Updated...`)
    }, error => {
      this.spinner = false;
    })
  }

  openSnackBar(message) {
    this._snackBar.openFromComponent(AlertComponent, {
      duration: 5 * 1000,
      horizontalPosition : "end",
      verticalPosition : "top",
      data: {message, icon: 'fa-user'}
    });
  }

}
