import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lehengas',
  templateUrl: './lehengas.component.html',
  styleUrls: ['./lehengas.component.css']
})
export class LehengasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      
  }

}
