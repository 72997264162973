import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../alert/alert.component';
import { ApiService } from "../service/api.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from "../service/token.service";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public form : any = {
    email: '',
    password: '',
    role: '2',
  };
  public token : any = "";
  public response : any = [];
  constructor(@Inject(DOCUMENT) private document: Document, private TokenService:TokenService, private _snackBar: MatSnackBar, private router: Router, private ApiService: ApiService, private ActivatedRoute:ActivatedRoute) { 
    
  }

  ngOnInit(): void {
    this.getToken();
    if(this.token != null){
      this.router.navigate(['/']);
    }
  }

  onSubmit(): void {
    this.ApiService.post('enduser/login', this.form).subscribe((data:any)=>{
      this.response = data;
      if(this.response.error){
        this.openSnackBar(`${this.response.error}`);
        return;
      }
      this.openSnackBar(`Hii ${this.response.user.name}, You are now logged in with Studiokanchi....`)
      this.TokenService.setToken(this.response.token);
      localStorage.setItem('email', JSON.stringify(this.response.user.email));
      // this.getToken();
      this.router.navigate(['/profile']);
    });
  }

  openSnackBar(message) {
    this._snackBar.openFromComponent(AlertComponent, {
      duration: 5 * 1000,
      horizontalPosition : "end",
      verticalPosition : "top",
      data: {message, icon: 'fa-user'}
    });
  }

  getToken = () =>{
    this.TokenService.getToken().subscribe((data:any)=>{
      this.token = data;
    });
  }

}
