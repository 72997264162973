import { Component, OnInit } from '@angular/core';
import { ApiService } from "../service/api.service";
import { CartService } from "../service/cart.service";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  public response : any = [];
  public cartInformation : any = [];
  public total:any = 0;
  constructor(private ApiService: ApiService,  private CartService:CartService) { }

  ngOnInit(): void {
    this.getCartInformation();
  }

  
  getCartInformation = () =>{
    this.CartService.getProduct().subscribe((data:any)=>{
      this.cartInformation = data;
      this.getTotal();
      console.log(this.cartInformation);
    });
  }

  removeCartItem = (product:any) =>{
    let conf = confirm('Are you sure ?');
    if(conf){
      this.CartService.removeCartItem(product);
      this.getTotal();
    }
  }

  getTotal = () =>{
    this.total = 0;
    this.cartInformation.forEach((element, index) => {
      let price = (element.splprice * element.quantity)
      this.total+= price;
    });
  }

}
