import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public token = JSON.parse(localStorage.getItem('token')!);
  public url : string = environment.apiUrl;
  public country:any;
  constructor(private http: HttpClient) {}

  postData(methodName: any, data: any): Observable<any> {
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
   });

    return this.http.post(this.url + methodName, data, { headers: reqHeader }).pipe(
      catchError(this.error)
    )
  }

  postImagesWithData(methodName: any, data: any): Observable<any> {
    let httpHeaders= new HttpHeaders();
    httpHeaders.append('Content-Type', 'multipart/form-data');
    httpHeaders.append("Authorization", "Basic " + btoa("username:password"));

    const httpOptions = {
      headers: httpHeaders
    };
    return this.http.post(this.url + methodName, data, httpOptions).pipe(
      catchError(this.error)
    )
  }

  post(methodName: any, formData: any): Observable<any> {
    return this.http.post(this.url + methodName, formData).pipe(
      catchError(this.error)
    )
  }

  getData(methodName: any) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
   });

    if(localStorage.getItem('token')){
      return this.http.get(this.url + methodName, { headers: reqHeader }).pipe(
        catchError(this.error)
      );
    }else{
      return this.http.get(this.url + methodName).pipe(
        catchError(this.error)
      );
    }

  }

  // Handle Errors
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
