
			<div class="section">
				<div class="container">
					<div class="row">
						<!-- Blog Posts -->
						<div class="col-12 col-lg-12">
							<!-- Blog Post 1 -->
                            <div class="row">
                                <div class="col-md-12">
                                    <h5>CAREER OPPORTUNITIES</h5>
                                    <p>At Studio Kanchi, we believe in fostering a dynamic and creative work environment where our team members can flourish and contribute to the growth of our saree business. As a leading saree designing company, we are always on the lookout for passionate, talented individuals who share our love for sarees and fashion. Join us on our journey to create exquisite and personalized sarees for our valued customers. Below are some of the career opportunities we offer:</p>
                                    
                                </div>
                                <div class="col-md-4">
                                    <div class="wrapper-3">
                                        <div class="orange-top orange-border"></div>
                                        <div class="orange-left orange-border"></div>
                                        <a class="white-card" routerLink="/contact">
                                          <img src="https://s3.amazonaws.com/levelup-assets/temp/design.png" alt="Design"/>
                                          <p class="category">Content Writer</p>
                                          <span class="CTA">Contact Us</span>
                                        </a>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="wrapper-3">
                                        <div class="orange-top orange-border"></div>
                                        <div class="orange-left orange-border"></div>
                                        <a class="white-card" routerLink="/contact">
                                          <img src="https://s3.amazonaws.com/levelup-assets/temp/design.png" alt="Design"/>
                                          <p class="category">Social Media Manager</p>
                                          <span class="CTA">Contact Us</span>
                                        </a>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="wrapper-3">
                                        <div class="orange-top orange-border"></div>
                                        <div class="orange-left orange-border"></div>
                                        <a class="white-card" routerLink="/contact">
                                          <img src="https://s3.amazonaws.com/levelup-assets/temp/design.png" alt="Design"/>
                                          <p class="category">Fashion Design Intern</p>
                                          <span class="CTA">Contact Us</span>
                                        </a>
                                      </div>
                                </div>
                            </div>
						</div>
					</div><!-- end row -->
				</div><!-- end container -->
			</div>
