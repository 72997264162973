import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { LehengasComponent } from './lehengas/lehengas.component';
import { HomeComponent } from './home/home.component';
import { SingleProductComponent } from './single-product/single-product.component';
import { OurstoryComponent } from './ourstory/ourstory.component';
import { ContactComponent } from './contact/contact.component';
import { CareerComponent } from './career/career.component';
import { FaqComponent } from './faq/faq.component';
import { SizeGuideComponent } from './size-guide/size-guide.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { CategoryproductComponent } from './categoryproduct/categoryproduct.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertComponent } from './alert/alert.component';
import { CartComponent } from './cart/cart.component';
import { ShippingComponent } from './shipping/shipping.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { SubcategoryproductComponent } from './subcategoryproduct/subcategoryproduct.component';

@NgModule({
  declarations: [
    AppComponent,
    LehengasComponent,
    HomeComponent,
    SingleProductComponent,
    OurstoryComponent,
    ContactComponent,
    CareerComponent,
    FaqComponent,
    SizeGuideComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    CategoryproductComponent,
    SubcategoryproductComponent,
    AlertComponent,
    CartComponent,
    ShippingComponent,
    RegisterComponent,
    LoginComponent,
    ProfileComponent,
    ThankyouComponent,
  ], 
  imports: [
    MatProgressSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    IvyCarouselModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatSnackBarModule
  ],
  providers: [{provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
