import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CareerComponent } from './career/career.component';
import { CartComponent } from './cart/cart.component';
import { CategoryproductComponent } from './categoryproduct/categoryproduct.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { AuthGuard } from './guard/auth.guard';
import { HomeComponent } from './home/home.component';
import { LehengasComponent } from './lehengas/lehengas.component';
import { LoginComponent } from './login/login.component';
import { OurstoryComponent } from './ourstory/ourstory.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { ShippingComponent } from './shipping/shipping.component';
import { SingleProductComponent } from './single-product/single-product.component';
import { SizeGuideComponent } from './size-guide/size-guide.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ThankyouComponent } from './thankyou/thankyou.component';

const routes: Routes = [
  {
    path:'',component:HomeComponent,
  },
  {
    path:"categories",
    component:LehengasComponent
  },
  {
    path:"single-product/:id",
    component:SingleProductComponent
  },
  {
    path:"ourstory",
    component:OurstoryComponent
  },
  {
    path:"contact",
    component:ContactComponent
  },
  {
    path:"career",
    component:CareerComponent
  },
  {
    path:"faq",
    component:FaqComponent
  },
  {
    path:"size-guide",
    component:SizeGuideComponent
  },
  {
    path:"privacy-policy",
    component:PrivacyPolicyComponent
  },
  {
    path:"term-condition",
    component:TermsConditionsComponent
  },
  {
    path:"category-product/:id",
    component:CategoryproductComponent
  },
  {
    path:"cart",
    component : CartComponent
  },
  {
    path:"shipping",
    component : ShippingComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"register",
    component : RegisterComponent
  },
  {
    path:"login",
    component : LoginComponent
  },
  {
    path:"profile",
    component : ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"thank-you/:id",
    component : ThankyouComponent,
    canActivate: [AuthGuard]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
